import { ButtonTertiary, ButtonSecondary } from '/features/buildingBlocks/Button'
import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'

import styles from './CookieBar.css'

export function CookieBar({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const reportError = useReportError()

  const {
    configuration,
    acceptAll,
    rejectAll,
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const displayConsent = configuration?.show ?? false

  return displayConsent ? (
    <aside className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <p className={styles.intro}>
          Deze website maakt gebruik van koekjes. Lees hier meer over op <a className={styles.link} href={routeMap.app.cookiePolicy({ language: 'nl' })} data-x='manage-cookies'>onze privacy pagina.</a>
        </p>
        <div className={styles.actions}>
          <ButtonTertiary onClick={acceptAll} layoutClassName={styles.buttonLayout} dataX='accept-cookies'>Accepteren</ButtonTertiary>
          <ButtonSecondary onClick={rejectAll} layoutClassName={styles.buttonLayout} dataX='reject-cookies'>Alleen noodzakelijk</ButtonSecondary>
        </div>
      </div>
    </aside>
  ) : <aside style={{ display: 'none' }} />
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
