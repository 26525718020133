import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { CookieBar } from './CookieBar'

const client = new QueryClient()

export default function CookieBarUniversal({ initialConfiguration, layoutClassName = undefined }) {
  return (
    <QueryClientProvider {...{ client }}>
      <CookieBar {...{ initialConfiguration, layoutClassName }} />
    </QueryClientProvider>
  )
}
